.button {
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  padding-inline: 2.25rem;
  background-color: #77000d;
  border-radius: 9999px;
  justify-content: center;
  color: #ffff;
  margin-left: 10px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 15px;
}

.icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.button:hover .icon {
  transform: translate(4px);
}

.button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.button::before {
  content: '';
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

/* From Uiverse.io by Yaya12085 */
/* From Uiverse.io by Yaya12085 */
.Refreshbutton {
  border: none;
  display: flex;
  padding: 0.55rem 1.5rem;
  background-color: #488aec;
  color: #ffffff;
  font-size: 0.95rem;
  line-height: 1rem;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  border-radius: 2rem;
  -webkit-user-select: none;
  user-select: none;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  box-shadow: 0 4px 6px -1px #488aec31, 0 2px 4px -1px #488aec17;
  opacity: 0;
  transform: translateY(-20px);
  animation: slideIn 0.6s forwards;
  position: relative;
  margin-top: -66px;
  margin-bottom: 30px;
  transition: transform 0.25s ease-out, box-shadow 0.25s ease-out;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Refreshbutton:hover {
  box-shadow: 0 10px 15px -3px #488aec4f, 0 4px 6px -2px #488aec17;
  background: #185cc1;
}

.Refreshbutton:focus,
.Refreshbutton:active {
  opacity: 0.85;
  box-shadow: none;
}

.Refreshbutton i {
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 5px;
}

/* From Uiverse.io by Creatlydev */
.ViewDetailsbutton {
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  border: none;
  cursor: pointer;
  align-items: center;
  gap: 0.75rem;
  background-color: #af3636;
  color: #fff;
  border-radius: 10rem;
  font-weight: 600;
  padding: 0.35rem 0.6rem;
  padding-left: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s;
  --clr: #7808d0;
}

.ViewDetailsbutton__icon-wrapper {
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  position: relative;
  color: #000000;
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.ViewDetailsbutton:hover {
  background-color: #77000d;
}

.ViewDetailsbutton:hover .ViewDetailsbutton__icon-wrapper {
  color: #000;
}

.ViewDetailsbutton__icon-svg--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.ViewDetailsbutton:hover .ViewDetailsbutton__icon-svg:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.ViewDetailsbutton:hover .ViewDetailsbutton__icon-svg--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
}
