
.NotificationMessageCard {
  /* max-width: 333px; */
  border-width: 1px;
  border-color: rgba(219, 234, 254, 1);
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 1);
  padding: 1rem;
  /* padding: 0.3rem 0.5rem; */
}

.NotificationMessage_header {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.NotificationMessage_icon {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  /* background: linear-gradient(#d7cfcf, #9198e5); */
  border:1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NotificationMessage_icon .icon {
  font-size: 25px;
  border-radius: 10px;
  color: black;
  /* margin-bottom: 9px; */
  /* margin-left: -4px; */
}

.NotificationMessage_alert {
  font-weight: 600;
  color: rgba(107, 114, 128, 1);
}

.NotificationMessage_message {
  margin-top: 1rem;
  color: rgba(107, 114, 128, 1);
}

.NotificationMessage_actions {
  margin-top: 1.5rem;
}

.NotificationMessage_actions a {
  text-decoration: none;
}

.NotificationMessage_mark-as-read,
.NotificationMessage_read {
  display: inline-block;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.75rem 1.25rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 800;
}

.NotificationMessage_read {
  /* background: linear-gradient(#d7cfcf, #9198e5); */
  background: #af3636;
  color: rgba(255, 255, 255, 1);
}

.NotificationMessage_mark-as-read {
  margin-top: 0.5rem;
  background-color: rgba(249, 250, 251, 1);
  color: rgba(107, 114, 128, 1);
  transition: all 0.15s ease;
}

.NotificationMessage_mark-as-read:hover {
  background-color: rgb(230, 231, 233);
}
