/* Base styles */
.chart-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 60px;
}

.chart {
  border: 2px solid #7f7f7f;
  border-radius: 10px;
  padding: 10px;
  height: 367px;
  width: 48%;
}

/* Responsive styles */

@media (max-width: 1199px) {
  .chart {
    width: 49%;
    height: 311px;
  }
}

@media (max-width: 991px) {
  .chart {
    width: 50%;
    height: 240px;
  }
}

@media (max-width: 767px) {
  .chart-container {
    display: block;
    margin-top: 60px;
  }
  .chart {
    width: 100%;
    height: 284px;
    margin-bottom: 10px;
  }
}

@media (max-width: 544px) {
  .chart {
    width: 100%;
    height: 297px;
  }
}

@media (max-width: 511px) {
  .chart {
    width: 100%;
    height: 240px;
  }
}

@media (max-width: 446px) {
  .chart {
    width: 100%;
    height: 211px; /* Minimum height for very small screens */
  }
}

@media (max-width: 418px) {
  .chart {
    width: 100%;
    height: 199px; /* Minimum height for very small screens */
  }
}

@media (max-width: 370px) {
  .chart {
    width: 103%;
    height: 222px;
  }
}

.main_card {
  background-color: #fff;
  height: fit-content;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
  border: 2px solid transparent;
}

.main_card:hover {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.18);
  border: 2px solid #77000d;
}

@media only screen and (max-width: 991px) {
  .main_card {
    margin-bottom: 20px;
  }
}

@media (max-width: 394px) {
  .main_card {
    padding: 6px 10px;
  }
  .main_card img {
    width: 50px;
  }
  .main_card p {
    font-size: 15px;
  }
}

.AddStudentcontainer {
  max-width: 1160px;
  margin: auto;
}

.main {
  background-color: #fff9f7;
  padding: 100px 20px 40px;
}

/* Responsive Styles */
@media (max-width: 991px) {
  .main_card {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .main_card {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .card-image {
    width: 50px;
  }

  .card-title {
    font-size: 1.1rem;
  }

  .detailCard p {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .main_card {
    padding: 15px;
  }

  .card-title {
    font-size: 1rem;
  }

  .detailCard p {
    font-size: 13px;
  }
}

.OrgStudent-section .table-box .scroll-table-box {
  overflow-x: scroll;
}
.OrgStudent-section {
  padding: 35px 0;
}

.shadow-box {
  box-shadow: 0px 4px 16px 0px #00000030;
  background: #ffffff;
  border-radius: 5px;
}

.OrgStudent-section h2 {
  margin: 0 0 25px;
  font-size: 30px;
  font-weight: 600;
  line-height: 35.16px;
}

.OrgStudent-section .table-box {
  /* border: 1px solid #77000D; */
  border-radius: 5px;
  overflow: hidden;
}

/* ===========Student css  */
.card-custom {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  background: white;
}
.badge-custom {
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 12px;
}
.badge-blue {
  background: #e0f2fe;
  color: #0369a1;
}
.badge-red {
  background: #fee2e2;
  color: #b91c1c;
}
.btn-custom {
  color: white;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  background: #af3636;
}
.btn-gray {
  background: #e5e7eb;
  color: #374151;
  border: none;
}
.btn-purple {
  background: #6d28d9;
  color: white;
  border: none;
}
.gap-4 > * {
  margin-bottom: 16px;
}

/* Light badge styles */
.badge-warning-light {
  background-color: #ffecb3; /* Lighter shade of yellow */
  color: #856404;
}

.badge-success-light {
  background-color: #d4edda; /* Lighter shade of green */
  color: #155724;
}

.badge-secondary-light {
  background-color: #e2e3e5; /* Lighter shade of gray */
  color: #6c757d;
}
