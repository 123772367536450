


.student-work-section {
    padding: 55px 0;
}


.student-work-section .inner-student-work {
    box-shadow: 0px 4px 16px 0px #00000030;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 54px 0;
    position: relative;
}

.student-work-section  h2 {
    margin: 0;
    font-size: 30px;
    font-weight: 600;
    line-height: 35.16px;
    text-align: center;
    padding: 0 45px;
}

.student-work-section .top-tabs {
    padding: 45px 45px;
    border-bottom: 1px solid #ECECEC;
}

.student-work-section .top-tabs ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.student-work-section .top-tabs ul li label {
    position: relative;
    min-height: 64px;
    display: inline-block;
    /* top: 22px; */
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #BBB9B9;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch .text-box {
    box-shadow: 0px 4px 13px 0px #00000012;
    padding: 18px 16px 18px 75px;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.44px;
    text-align: left;
    width: 100%;
    display: block;
}

.switch .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 23px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch  input:checked + .slider {
    background: #990033;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}


.switch input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

.switch .slider.round {
  border-radius: 34px;
  width: 50px;
  height: 27px;
  left: 16px;
  top: 16px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.student-work-section .top-tabs ul li {
    margin: 0 15px;
}

.switch input:checked ~ .text-box {
    font-weight: 500;
    border: 1px solid #990033;
}

.student-work-section .tabs-content {
    padding: 45px 45px;
}

.student-work-section .tabs-content h3 {
    margin: 0 0 70px;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 35.16px;
}

.student-work-section .tabs-content .text-img-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.student-work-section .tabs-content .student-details-box {
    width: calc(33.33% - 14px);
    margin: 7px;
}

.student-work-section .tabs-content .student-details-box  p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.09px;
}

.student-work-section .tabs-content .student-details-box .com-student-details {
    display: flex;
    align-items: center;
    padding: 150px 0 0;
}

.student-work-section .tabs-content .student-details-box .com-student-details .counter-box {
    width: 56px;
    margin-left: 20px;
    border: 4px solid rgb(153 0 51 / 10%);
    border-radius: 50%;
}

.student-work-section .tabs-content .student-details-box .com-student-details .text-box {
    width: calc(100% - 76px);
}

.student-work-section .tabs-content .student-details-box .com-student-details .counter-box span {
    background: #990033;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: left;
}

/* .student-work-section .tabs-content .student-details-box .com-student-details .counter-box span:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: -4px;
    left: -4px;
    border: 4px solid rgb(153 0 51 / 10%);
    border-radius: 50%;
} */

.student-work-section .tabs-content .text-img-box .left-box.student-details-box {
    text-align: right;
}

.student-work-section .tabs-content .mt-auto {
    display: block;
    margin-top: auto;
}

.student-work-section .tabs-content .student-details-box h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28.13px;
    margin: 0 0 8px;
}

.student-work-section .tabs-content .student-details-box .com-student-details:nth-child(1) {
    padding: 0;
}

.student-work-section .tabs-content .right-box.student-details-box  .text-box {
    padding-right: 0;
}

.student-work-section .tabs-content .right-box.student-details-box  .counter-box {
    margin-left: 0;
    margin-right: 20px;
}

.student-work-section .tabs-content .btn-box {
    display: flex;
    margin: 50px auto 0;
    width: 100%;
    justify-content: center;
}

.student-work-section .tabs-content .btn-box .btn.btn-primary {
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    background: #990033;
    padding: 14px 50px;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.09px;
    color: #fff;
    text-decoration: none;
}

.student-work-section .tabs-content .student-details-box .img-box img {
    width: 100%;
    display: block;
}



@media only screen and (max-width:1200px){



    .student-work-section {
        padding: 35px 0;
    }
    
    .student-work-section .inner-student-work {
        padding: 35px 0 0;
    }
    
    .student-work-section h2 {
        padding: 0 15px;
    }
    
    .student-work-section .top-tabs {
        padding: 45px 15px;
    }
    
    .student-work-section .tabs-content {
        padding: 45px 15px;
    }
    
    .student-work-section .tabs-content .student-details-box h4 {
        font-size: 20px;
    }
    

}



@media only screen and (max-width:993px){


    .student-work-section h2 {
        font-size: 24px;
        line-height: 34px;
    }
    
    .student-work-section .top-tabs {
        padding: 25px 15px;
    }
    
    .student-work-section .top-tabs ul li {
        margin: 0 2px;
    }
    
    .switch .text-box {
        font-size: 16px;
    }
    
    .switch .slider.round {
        height: 20px;
        top: 19px;
        width: 44px;
    }
    
    .switch .slider:before {
        width: 16px;
        height: 16px;
        bottom: 2px;
    }
    
    .student-work-section .tabs-content h3 {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 40px;
    }
    
    .student-work-section .tabs-content {
        padding: 30px 15px;
    }
    
    .student-work-section .tabs-content .student-details-box {
        width: 100%;
    }
    
    
    .student-work-section .tabs-content .student-details-box .com-student-details {
        padding: 15px 0 !important;
    }
    
    .student-work-section .tabs-content .center-box.student-details-box {
        order: 3;
    }
    
    .student-work-section .tabs-content .text-img-box .left-box.student-details-box .student-position-box {display: flex;flex-wrap: wrap;flex-direction: column-reverse;}
    
    .student-work-section .tabs-content .text-img-box .left-box.student-details-box {margin: 0;order: 2;}
    
    .student-work-section .tabs-content .text-img-box .left-box.student-details-box  .com-student-details {
        flex-direction: row-reverse;
        text-align: left;
    }
    
    .student-work-section .tabs-content .student-details-box .com-student-details .counter-box {
        margin-left: 0;
        margin-right: 20px;
    }
    
    .student-work-section .tabs-content .right-box.student-details-box {
        order: 1;
    }

}



@media only screen and (max-width:767px){


    .student-work-section .top-tabs ul li {
        width: 100%;
        margin: 0 0 10px;
    }
    
    .student-work-section .top-tabs ul li label {
        max-width: 262px;
        width: 100%;
        margin: 0 auto;
        display: block;
    }
    
    .student-work-section .tabs-content h3 {
        margin-bottom: 20px;
    }
    
    .student-work-section .tabs-content .student-details-box .com-student-details .counter-box span {
        width: 26px;
        height: 26px;
        font-size: 16px;
        line-height: 19px;
    }
    
    .student-work-section .tabs-content .student-details-box .com-student-details .counter-box {
        width: 35px;
    }
    
    .student-work-section .tabs-content .right-box.student-details-box .counter-box {
        margin-right: 10px;
    }
    
    .student-work-section .tabs-content .student-details-box .com-student-details .text-box {
        width: calc(100% - 35px);
    }
    
    .student-work-section .tabs-content .student-details-box .com-student-details .counter-box {
        margin-right: 10px;
    }
    
    .student-work-section .tabs-content .student-details-box {
        margin: 0;
    }
    
    .student-work-section .tabs-content .student-details-box h4 {
        font-size: 16px;
        line-height: 21px;
    }
    
    .student-work-section .tabs-content .student-details-box p {
        font-size: 15px;
    }
    
    .student-work-section .tabs-content .student-details-box .com-student-details {
        align-items: flex-start;
    }

}
