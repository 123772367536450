.form-container {
  max-width: 600px;
  margin: 0 auto;
}

.form-groupForAddStudnet {
  display: flex;
  position: relative;
}

.dropdown-button {
  flex-shrink: 0;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
}

.dropdown-button svg {
  margin-left: 8px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 150px;
}

.dropdown ul {
  list-style: none;
  padding: 10px 0;
  margin: 0;
}

.dropdown li {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #f0f0f0;
}

.search-input {
  flex-grow: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 5px 5px 0;
  outline: none;
}

.submit-button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 10px;
  font-size: 14px;
  color: #fff;
  background-color: #af3636;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.submit-button svg {
  vertical-align: middle;
}

.submit-button:hover {
  background-color: #0056b3;
}
