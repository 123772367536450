.message-container {
  width: 530px;
  margin: 20px auto;
}

@media (max-width: 767px) {
  .message-body {
    padding: 0px;
  }

}
.message-header span{
  font-size: 1.5em;
}

@media (max-width: 544px) {
  .message-container {
    width: 492px;
    margin: 10px auto;
}
.message-header span{
  font-size: 1.2em;
}
}

@media (max-width: 511px) {
  .message-container {
    width: 400px;
    margin: 10px auto;
}
.message-header span{
  font-size: 1em;
}
.message-body {
  height: 433px;
}
}

@media (max-width: 418px) {
  .message-container {
    width: 350px;
    margin: 10px auto;
}
.message-header span{
  font-size: 0.9em;
}
}

@media (max-width: 390px) {
  .message-container {
    width: 315px;
    margin: 10px -3px;
    height: 600px;
}
.message-header span{
  font-size: 1.5em;
}
.message-body {
  height: 433px;
}
}

.message-body {
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  background-color: #fff;
}
.message-header,
.message-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.message-footer {
  margin-top: 20px;
}
