/* Width and height of the scrollbar */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  height: 10px; /* Height of the scrollbar */
}

/* Track (the area behind the scrollbar) */
::-webkit-scrollbar-track {
  background: none; /* Color of the track */
}

/* Handle (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: #ac524d; /* Color of the handle */
  border-radius: 5px; /* Roundness of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a9241e; /* Color of the handle on hover */
}

.sponsorship-requests-section {
  padding: 35px 0;
}

.shadow-box {
  box-shadow: 0px 4px 16px 0px #00000030;
  background: #ffffff;
  border-radius: 5px;
}

.sponsorship-requests-section h2 {
  margin: 0 0 25px;
  font-size: 30px;
  font-weight: 600;
  line-height: 35.16px;
}

.sponsorship-requests-section .table-box {
  /* border: 1px solid #77000D; */
  border-radius: 5px;
  overflow: hidden;
}

.table-box table tr th {
  background: #a50d1d;
  font-size: 18px;
  font-weight: 500;
  line-height: 15px;
  color: #fff;
  padding: 20px 15px;
  text-align: left;
  font-weight: 600;
}

.table-box table tr td {
  padding: 9px 15px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  border-bottom: 1px solid #ececec;
  color: #363636;
}

.table-sec {
  margin-bottom: 55px;
}

.table-sec:last-child {
  margin-bottom: 0;
}

.table-box table tr:nth-child(2n + 2) td {
  background: #ffe7e7;
  border-bottom: 1px solid transparent;
}

.table-box table tr td .text-img-table {
  display: flex;
  align-items: center;
}

.table-box table tr td .text-img-table .text-table {
  padding-left: 9px;
  font-weight: 500;
}

.table-box table tr td .text-img-table .text-table span {
  display: block;
  font-weight: 400;
  color: #808080;
  margin-top: 4px;
}

.active-dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #0abf53;
  border-radius: 50%;
  margin-right: 6px;
}

.line-shape {
  background: #d9d9d9;
  width: 80px;
  height: 6px;
  display: inline-block;
  border-radius: 10px;
  margin-right: 6px;
}

.table-box table tfoot tr td {
  /* background: #FFE7E7; */
  border-bottom: 1px solid transparent;
  text-align: right;
}

.table-box table tfoot tr td .table-footer-box {
  display: flex;
  align-items: center;
  justify-content: end;
}

.table-box table tfoot tr td .table-footer-box .left-arrow,
.table-box table tfoot tr td .table-footer-box .right-arrow {
  margin: 0 17px;
}

.table-box table tfoot tr td .table-footer-box .center-content {
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  color: #000;
  display: inline-block;
}

.table-box table tfoot tr td .table-footer-box .center-content svg {
  margin: 0 7px;
}

.table-box table tfoot tr td .table-footer-box select {
  border: none;
  background-color: transparent;
  appearance: none;
  min-width: 74px;
  background-image: url(../assets/newui/downarrow.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 12px;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
}
.table-box table tr td.no-record {
  padding: 120px 0;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.44px;
}

@media only screen and (max-width: 767px) {
  .bannner-image {
    margin-top: 75px;
  }

  .MuiBox-root.MuiBox-root-78 {
    padding: 15px 0 !important;
  }

  .shadow-box {
    /* padding: 15px; */
  }

  .sponsorship-requests-section {
    padding: 15px 0;
  }

  .MuiBox-root-30 {
    padding: 0 !important;
  }
}
