.form-control[readonly],
.form-control-plaintext {
  background-color: #f8f9fa;
  border: none;
}
.form-control {
  padding: 0.5rem;
  box-shadow: none;
}
.input-group-text {
  background-color: #f8f9fa;
  border: none;
}
.form-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.btn-primary[disabled] {
  background-color: grey;
  border-color: grey;
}
.btnn {
  background-color: #af3636;
  color: white;
}


